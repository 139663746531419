import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Customers from './Customers';


const CustomersLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex -h-full mx-auto text-center max-w-screen">
      <Routes>
        <Route path="/" element={<Customers />} />
      </Routes>
    </div>
  );
};

export default CustomersLayout;
