import { useEffect, useState, useMemo, HTMLProps, useRef } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import ModalLayout from '@/shared/components/ModalLayout';
import UploadModal from './UploadModal';
import { PageHeader } from '@/shared/components/PageHeader';
import { Inventory as InventoryType } from '@/types';
import cn from '@/shared/helpers/classNames';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import DebouncedInput from '@/shared/components/DebouncedInput';
import IndeterminateCheckbox from '@/shared/components/Checkbox';
import { fuzzyFilter } from '@/shared/helpers/tablefilters';
import { exportSelectedRows } from '@/shared/helpers/exportSelectedRows';
import { Pagination } from '@/shared/components/Pagination';
import { usePaginationStore } from '@/store';
import { useAuth } from '@clerk/clerk-react';
import Loading from '@/shared/components/Loading';

const Inventory: React.FC = () => {
  const apiUrl = window.env?.API_URL || '';

  const [data, setData] = useState<InventoryType[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfItemsPerPage = usePaginationStore(state => state.numberOfItemsPerPage);

  const { getToken } = useAuth();
  const fetchData = async () => {
    setLoading(true);
    const token = await getToken();
    fetch(`${apiUrl}/inventory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then((data: InventoryType[]) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data from API', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }: { table: any }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: any }) => (
          <div className="flex items-center">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'SKU',
        accessorKey: 'sku',
      },
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Quantity',
        accessorKey: 'quantity',
      },
      {
        header: 'Unit',
        accessorKey: 'unit',
      },
    ],
    []
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const tableInstance = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: numberOfItemsPerPage,
      },
    },
  });

  // when number of items per page changes, update the table
  useEffect(() => {
    tableInstance.setPageSize(numberOfItemsPerPage);
  }, [numberOfItemsPerPage]);

  const handleExport = () => {
    const selectedRows = tableInstance.getFilteredSelectedRowModel().rows;
    const columnMapping = {
      sku: 'SKU',
      name: 'Name',
      quantity: 'Quantity',
      unit: 'Unit',
    };
    exportSelectedRows(selectedRows, 'inventory_export', columnMapping);
  };

  return (
    <div className="flex flex-col w-full px-4">
      <Loading loading={loading} />
      <div className="relative">
        <PageHeader
          title="Inventory"
          subHeading=""
          leftElement={
            <div className="flex flex-col sm:flex-row">
              <button
                title="Supports CSV files only."
                className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 sm:mr-2"
                onClick={() => setModalOpen(true)}
              >
                Upload
              </button>
              <button
                onClick={handleExport}
                title="Exports as CSV. Select one or more inventory items to export."
                disabled={tableInstance.getFilteredSelectedRowModel().rows.length === 0}
                className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 disabled:opacity-50"
              >
                Export
              </button>
            </div>
          }
        />
      </div>
      <div className="bg-white text-slate-900 flex flex-col w-full rounded-lg">
        <ModalLayout
          open={modalOpen}
          modalWidthClass="w-full overflow-x-auto max-w-7xl"
          onCloseClicked={() => setModalOpen(false)}
        >
          <UploadModal
            onCloseClicked={() => setModalOpen(false)}
            onSaved={() => setModalOpen(false)}
            file={file}
            setFile={setFile}
            apiUrl={apiUrl}
            fetchData={fetchData}
          />
        </ModalLayout>

        <div className="flex flex-col sm:flex-row px-6 mt-6">
          <div className="flex-1 mb-2 sm:mb-0 sm:mr-2">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full"
              placeholder="Search..."
            />
          </div>
        </div>

        <div className="w-full mx-auto">
          <div className="space-x-4 p-6 w-full overflow-x-auto">
            <table className="min-w-full bg-white w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                {tableInstance.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan} className="px-6 py-3">
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: cn(
                                  'flex items-center tracking-wider cursor-pointer select-none',
                                  header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                ),
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <FaSortUp />,
                                desc: <FaSortDown />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>{/* <Filter column={header.column} /> */}</div>
                            ) : null}
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {tableInstance.getRowModel().rows.map(row => (
                  <tr key={row.id} className="bg-white border-b hover:bg-gray-50">
                    {row.getVisibleCells().map(cell => (
                      <td className="px-6 py-3" key={cell.column.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="p-6">
            <Pagination
              totalItems={tableInstance.getRowCount()}
              currentPage={currentPage}
              onPageChange={(page: { selected: number }) => {
                setCurrentPage(page.selected);
                tableInstance.setPageIndex(page.selected);
              }}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inventory;
