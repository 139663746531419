import { IntegrationsCard } from '@/shared/components/IntegrationsCard';
import { PageHeader } from '@/shared/components/PageHeader';

const Integrations: React.FC = () => {
  return (
    <div className="flex flex-col w-full px-4 mt-4">
      <div className="relative">
        <PageHeader
          title="Integrations"
          subHeading=""
          leftElement={<div className="flex flex-col sm:flex-row"></div>}
        />
      </div>
      <div className="bg-white text-slate-900 flex flex-col w-full rounded-lg mt-4 max-w-full overflow-auto p-3">
        <div className="w-full mx-auto">
          <h2 className="text-xl px-6 pt-7 text-left text-black">All integrations</h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
            {/* Card */}
            <IntegrationsCard
              title="SAGE300 Integration"
              subHeading="Automate order processing with SAGE300 compatibility."
              image="https://orderecho.ai/wp-content/uploads/2024/06/sage300com.png"
            />

            <IntegrationsCard
              title="Zoho Integration"
              subHeading="Integrate orders effortlessly with Zoho."
              image="https://orderecho.ai/wp-content/uploads/2024/08/2.png"
            />

            <IntegrationsCard
              title="Xero Integration"
              subHeading="Sync orders seamlessly into Xero accounting."
              image="https://orderecho.ai/wp-content/uploads/2024/08/3.png"
            />

            <IntegrationsCard
              title="SysPro Integration"
              subHeading="Enhance efficiency with SysPro order integration."
              image="https://orderecho.ai/wp-content/uploads/2024/08/4.png"
            />

            <IntegrationsCard
              title="SAP Business One Integration"
              subHeading="Automate order management with SAP Business One."
              image="https://orderecho.ai/wp-content/uploads/2024/08/5.png"
            />

            <IntegrationsCard
              title="SAGE Integration"
              subHeading="Streamline orders directly into your SAGE system."
              image="https://orderecho.ai/wp-content/uploads/2024/08/6.png"
            />

            <IntegrationsCard
              title="QuickBooks Integration"
              subHeading="Easily manage orders within QuickBooks."
              image="https://orderecho.ai/wp-content/uploads/2024/08/7.png"
            />

            <IntegrationsCard
              title="Odoo Integration"
              subHeading="Simplify order processing with Odoo integration."
              image="https://orderecho.ai/wp-content/uploads/2024/08/8.png"
            />

            <IntegrationsCard
              title="NetSuite Integration"
              subHeading="Integrate orders seamlessly into NetSuite."
              image="https://orderecho.ai/wp-content/uploads/2024/08/9.png"
            />

            <IntegrationsCard
              title="Microsoft Dynamics Integration"
              subHeading="Sync orders directly with Microsoft Dynamics."
              image="https://orderecho.ai/wp-content/uploads/2024/08/10.png"
            />

            <IntegrationsCard
              title="Katana Integration"
              subHeading="Streamline production orders with Katana."
              image="https://orderecho.ai/wp-content/uploads/2024/08/11.png"
            />

            <IntegrationsCard
              title="Fishbowl Integration"
              subHeading="Manage inventory and orders with Fishbowl."
              image="https://orderecho.ai/wp-content/uploads/2024/08/12.png"
            />

            <IntegrationsCard
              title="Epicor Integration"
              subHeading="Automate order workflows with Epicor."
              image="https://orderecho.ai/wp-content/uploads/2024/08/13.png"
            />

            <IntegrationsCard
              title="Dear Systems Integration"
              subHeading="Simplify order management in Dear Systems."
              image="https://orderecho.ai/wp-content/uploads/2024/08/14.png"
            />

            <IntegrationsCard
              title="Acumatica Integration"
              subHeading="Boost efficiency with Acumatica order integration."
              image="https://orderecho.ai/wp-content/uploads/2024/08/15.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
