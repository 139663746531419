import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Inventory from './Inventory';


const InventoryLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex -h-full mx-auto text-center max-w-screen">
      <Routes>
        <Route path="/" element={<Inventory />} />
      </Routes>
    </div>
  );
};

export default InventoryLayout;
