// Fetch order items CSV from server
async function exportOrders(orderIds: number[], token: string | null = null) {
  const apiUrl = window.env?.API_URL || "";
  const response = await fetch(`${apiUrl}/order-export?orderIds=${orderIds.join(',')}`, {
    headers: {
      // zip file
      // 'Content-Type': 'application/zip',
      'Content-Type': 'text/csv',
      Authorization: `Bearer ${token || ''}`
    }
  });

  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // current unix timestamp as filename
    const timestamp = formatDate() //new Date().getTime();
    // a.download = `orders-${timestamp}.zip`;
    a.download = `orders-${timestamp}.csv`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } else {
    console.error("Error fetching zip:", response.statusText);
  }
}

function formatDate() {
  const date = new Date();
  
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  
  return `${month}-${day}-${year}_${hours}-${minutes}-${seconds}`;
}

export default exportOrders;