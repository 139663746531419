import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react'

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// throw error if clerk publishable key is missing
const PUBLISHABLE_KEY = window.env?.VITE_CLERK_PUBLISHABLE_KEY || "pk_test_ZXRoaWNhbC1jb3JhbC03Ni5jbGVyay5hY2NvdW50cy5kZXYk"
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
