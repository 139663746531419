import Select, { MultiValue, SingleValue } from 'react-select';
import cn from '@helpers/classNames';
import { UseFormRegisterReturn } from 'react-hook-form';

export type SelectOptionType = {
  label: string | number;
  value: string | number | undefined;
};

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  label?: string;
  options: SelectOptionType[] | { label: string; options: Option[] }[]; // to make it work with the filter group in search bar
  isClearable?: boolean;
  searchable?: boolean;
  value?: SingleValue<SelectOptionType> | MultiValue<SelectOptionType> | undefined | null;
  onChange?: (value: SingleValue<SelectOptionType> | MultiValue<SelectOptionType> | null) => void;
  className?: string;
  isMulti?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  help?: string[] | undefined;
  error?: string | string[] | undefined;
  register?: UseFormRegisterReturn;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  onInputChange?: (value: string) => void;
}

export const SelectComponent = ({
  label,
  options,
  isClearable = false,
  searchable = false,
  value,
  onChange,
  className = '',
  isMulti = false,
  isLoading = false,
  placeholder,
  register,
  error = '',
  help = [],
  menuPlacement = 'auto',
  onInputChange,
}: SelectProps) => {
  return (
    <div className="flex flex-col w-full space-y-2">
      <Select
        styles={{
          menuPortal: base => ({ ...base, zIndex: 99999 }),
          input: base => ({
            ...base,
            'input:focus': {
              // this removes weird tailwind style collision
              boxShadow: 'none',
            },
          }),
          // change the default hover border
          control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            '&:hover': {
              border: '1px solid rgb(16 185 129 / var(--tw-border-opacity))',
            },
            '&:focus-within': {
              border: '1px solid rgb(16 185 129 / var(--tw-border-opacity))',
            },
          }),
        }}
        {...(value ? { value } : {})}
        {...(label ? { placeholder: label } : {})}
        className={cn(
          'block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
          className
        )}
        classNamePrefix="select"
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        // defaultValue={colourOptions[0]}
        isDisabled={false}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={false}
        isSearchable={searchable}
        onInputChange={onInputChange}
        name="color"
        // maxMenuHeight={900}
        minMenuHeight={600}
        // onChange={onChange}
        // onChange={value => {
        //   // if (isMulti) {
        //   onChange(value);
        //   // } else {
        //   // onChange(value as SelectOptionType);
        //   // }
        // }}
        {...(register ? { register } : {})}
        {...(onChange ? { onChange } : {})}
        options={options}
        isMulti={isMulti}
      />
      {typeof error === 'string' && error.length > 0 && (
        <p className="mt-2 text-sm text-pink-600" id="error">
          {error}
        </p>
      )}
      {typeof error !== 'string' && error.length > 0 && (
        <div className="flex flex-col">
          {error.map((e, i) => (
            <p className="mt-2 text-sm text-pink-600" id="error" key={i}>
              {e}
            </p>
          ))}
        </div>
      )}
      {help.length > 0 && (
        <div className="flex flex-col">
          {help.map((e, i) => (
            <p className="mt-2 text-sm text-gray-500" id="help" key={i}>
              {e}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
