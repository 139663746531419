import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cn from '../helpers/classNames';


interface ModalLayoutProps {
  children: React.ReactNode;
  open: boolean;
  onCloseClicked: () => void;
  className?: string;
  modalWidthClass?: string;
  externalCloseControl?: boolean;
}

export const ModalLayout = ({
  children,
  onCloseClicked,
  open,
  className = '',
  modalWidthClass = '',
  externalCloseControl = false,
}: ModalLayoutProps) => {
  // NOTE: because this is a modal, remember to clean up any state when the modal is closed

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[250]"
        onClose={() => {
          if (externalCloseControl) {
            onCloseClicked();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className={cn('fixed inset-0 z-10 overflow-y-auto', className)}>
          <div className="justify-center p-4 text-center sm:flex mt-11 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'relative px-4 pt-5 pb-4 text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:p-6',
                  modalWidthClass ? modalWidthClass : 'sm:max-w-2xl sm:w-full'
                )}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 ">
                  <button
                    type="button"
                    className={cn(
                      'text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2'
                    )}
                    onClick={onCloseClicked}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalLayout;
