export const TranscriptInfo = ({ transcript }: { transcript?: string }) => {
  // Parsing the transcript string
  const parsedTranscript = JSON.parse(transcript || '[]');

  return (
    <div className="flex flex-col bg-white shadow-sm p-6 text-left rounded-lg h-[400px] overflow-y-auto">
      <h1 className="font-bold mb-4">Conversation Transcript:</h1>
      <div className="flex flex-col space-y-2">
        {parsedTranscript.map((line: string, index: number) => {
          const [role, message] = line.split(': ');
          return (
            <div key={index} className="flex flex-col">
              <span className="font-bold text-sm">{role}:</span>
              <span className="text-sm">{message}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TranscriptInfo;
