import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Orders from './Orders';
import OrderDetails from './OrderDetails';


const OrdersLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex -h-full mx-auto text-center max-w-screen">
      <Routes>
        <Route path="/" element={<Orders />} />
        <Route path=":id" element={<OrderDetails />} />
      </Routes>
    </div>
  );
};

export default OrdersLayout;
