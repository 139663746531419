import { useEffect, useState, useMemo } from 'react';
import { PageHeader } from '@/shared/components/PageHeader';
import {
  OrderItem as OrderItemType,
  Order as OrderType,
  Customer as CustomerType,
  Transcript as TranscriptType,
  Employee as EmployeeType,
} from '@/types';
import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import IndeterminateCheckbox from '@/shared/components/Checkbox';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import cn from '@/shared/helpers/classNames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '@/shared/helpers/formatDate';
import { dateRangeFilter, fuzzyFilter } from '@/shared/helpers/tablefilters';
import { exportSelectedRows } from '@/shared/helpers/exportSelectedRows';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerInfo from '@/shared/components/CustomerInfo';
import TranscriptInfo from '@/shared/components/TranscriptInfo';
import exportOrders from './exportOrders';
import { useAuth } from '@clerk/clerk-react';
import Switch from '@/shared/components/Switch';
import Loading from '@/shared/components/Loading';

type Response = {
  customer: CustomerType;
  order: OrderType;
  transcript: TranscriptType;
  items: OrderItemType[];
  employee: EmployeeType;
};

const OrderDetails: React.FC = () => {
  const apiUrl = window.env?.API_URL || '';
  // get id from url
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, setData] = useState<Response | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const createdAtFormatted = data?.order?.created_at
    ? formatDate({
        date: data?.order?.created_at,
        format: 'MMMM Do YYYY',
      })
    : '';

  // const subheadingText = data ? `${data?.customer?.name || ""} - ${createdAtFormatted}` : '';
  const subheadingText = data ? (
    <>
      <div className="font-semibold">{data?.customer?.name || ''}</div>
      <div className="text-sm text-gray-500">Ordered on: {createdAtFormatted}</div>
      <div className="text-sm text-gray-500">Delivery date: {data?.order?.delivery_date || ''}</div>
      <div className="text-sm text-gray-500">Sales rep: {data?.employee?.name || ''}</div>
    </>
  ) : (
    ''
  );

  const { getToken } = useAuth();

  const fetchData = async () => {
    setLoading(true);
    const token = await getToken();
    fetch(`${apiUrl}/orders/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then((data: Response) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data from API', error);
        // redirect to orders page
        navigate('/orders');
        setLoading(false);
      });
  };

  async function markOrderAsFulilled(orderId: number, exported: boolean) {
    setLoading(true);
    const token = await getToken();
    const url = `${apiUrl}/order-fulfilled`;
    const data = {
      orderId: orderId.toString(),
      exported: exported.toString(),
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        console.log(jsonResponse.message);
        // refresh data
        fetchData();
        setLoading(false);
      } else {
        console.error('Error:', jsonResponse.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error marking order as fulfilled:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const exportOrder = async () => {
    if (!id) return;
    const token = await getToken();
    const idNum = Number(id);
    // ignore if not a number
    if (isNaN(idNum)) return;
    const ids = [idNum];
    await exportOrders(ids, token);
    // refresh data
    fetchData();
  };

  const columns = useMemo(
    () => [
      {
        id: 'sku',
        header: 'SKU',
        accessorKey: 'sku',
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
      },
      {
        id: 'quantity',
        header: 'Quantity',
        accessorKey: 'quantity',
      },
      {
        id: 'unit',
        header: 'Unit',
        accessorKey: 'unit',
      },
      {
        id: 'custom_price',
        header: 'Custom Price',
        accessorKey: 'custom_price',
      },
    ],
    []
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    const newFilters = columnFilters.filter(filter => filter.id !== 'created_at');
    if (startDate || endDate) {
      newFilters.push({ id: 'created_at', value: [startDate, endDate] });
    }
    setColumnFilters(newFilters);
  }, [startDate, endDate]);

  const tableInstance = useReactTable({
    columns,
    data: data?.items || [],
    filterFns: {
      fuzzy: fuzzyFilter,
      dateRange: dateRangeFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleExport = () => {
    const selectedRows = tableInstance.getFilteredSelectedRowModel().rows;
    const columnMapping = {
      sku: 'SKU',
      name: 'Name',
      quantity: 'Quantity',
      unit: 'Unit',
      created_at: 'Created At',
    };
    exportSelectedRows(selectedRows, 'orders_export', columnMapping);
  };

  return (
    <div className="flex flex-col w-full px-4">
      <Loading loading={loading} />
      <div className="relative">
        <PageHeader
          title="Order Details"
          subHeading=""
          leftElement={
            <button
              type="button"
              onClick={exportOrder}
              className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 disabled:opacity-50"
            >
              Export Order
            </button>
          }
        />
      </div>
      <div className="bg-slate-200 text-slate-900 flex flex-col w-full rounded-lg">
        <div className="w-full mx-auto flex flex-col lg:flex-row py-6 pt-1 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="w-full lg:w-2/3">
            {/* <div className="flex p-4">
            Fulfilled: <span className="ml-1 font-semibold">
              <Switch
            checked={!!data?.order?.exported_at}
            onChange={(checked) => markOrderAsFulilled(data?.order?.id || 0, checked)}
          />
          </span>
          </div> */}
            <div className="overflow-x-auto rounded-lg h-[450px] bg-white">
              <table className="min-w-full bg-white w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  {tableInstance.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} colSpan={header.colSpan} className="px-6 py-3">
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                {...{
                                  className: cn(
                                    'flex items-center tracking-wider cursor-pointer select-none',
                                    header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                  ),
                                  id: header.id,
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: <FaSortUp />,
                                  desc: <FaSortDown />,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>{/* <Filter column={header.column} /> */}</div>
                              ) : null}
                            </>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {tableInstance.getRowModel().rows.map(row => (
                    <tr key={row.id} className="bg-white border-b hover:bg-gray-50">
                      {row.getVisibleCells().map(cell => (
                        <td className="px-6 py-3 text-gray-800" key={cell.column.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full lg:w-1/3 space-y-4 ">
            <div className="flex flex-col bg-white shadow-sm px-6 pt-4 text-left text-sm rounded-lg h-[140px] overflow-y-auto">
              <div className="flex flex-col space-y-2">{subheadingText}</div>
            </div>
            <CustomerInfo customer={data?.customer} employee={data?.employee} />
          </div>
        </div>
        <div className="w-full mx-auto pb-6">
          <TranscriptInfo transcript={data?.transcript?.transcription} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
