import { ReactElement } from 'react';

export const PageHeader = ({
  title,
  subHeading,
  leftElement,
}: {
  title: string;
  subHeading: string | ReactElement;
  leftElement: ReactElement;
}) => {
  return (
    <div className="flex md:flex-row flex-col items-center bg-slate-200 w-full pb-1 justify-between">
      <div className="flex flex-col p-1">
        <h1 className="text-2xl text-center md:text-left font-bold text-slate-900 pb-2">{title}</h1>
        <h2 className="text-lg text-center md:text-left  text-slate-600">{subHeading}</h2>
      </div>
      <div className="flex space-x-4 py-4">{leftElement}</div>
    </div>
  );
};
