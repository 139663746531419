
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import generateGuid from './shared/helpers/generateGuid';
import { createJSONStorage, persist } from 'zustand/middleware';


// d888888b  .d88b.   .d8b.  .d8888. d888888b .d8888.
// `~~88~~' .8P  Y8. d8' `8b 88'  YP `~~88~~' 88'  YP
//    88    88    88 88ooo88 `8bo.      88    `8bo.
//    88    88    88 88~~~88   `Y8b.    88      `Y8b.
//    88    `8b  d8' 88   88 db   8D    88    db   8D
//    YP     `Y88P'  YP   YP `8888Y'    YP    `8888Y'

export interface ToastType {
  id?: string;
  heading: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

export interface ToastStoreProps {
  toasts: ToastType[];
  addToast: (toast: ToastType) => void;
  removeToast: (toast: ToastType) => void;
  clearToasts: () => void;
}

export const useToastStore = create(
  immer<ToastStoreProps>(set => ({
    // toasts: testToasts,
    toasts: [],
    addToast: ({ heading, message, type, id }: ToastType) =>
      set(state => {
        state.toasts.push({
          id: id || generateGuid(),
          heading,
          message,
          type,
        });
      }),
    removeToast: (toast: ToastType) =>
      set(state => {
        state.toasts = state.toasts.filter(t => t.id !== toast.id);
      }),
    clearToasts: () => set(() => ({ toasts: [] })),
  }))
);


// d8888b.  .d8b.   d888b  d888888b d8b   db  .d8b.  d888888b d888888b  .d88b.  d8b   db
// 88  `8D d8' `8b 88' Y8b   `88'   888o  88 d8' `8b `~~88~~'   `88'   .8P  Y8. 888o  88
// 88oodD' 88ooo88 88         88    88V8o 88 88ooo88    88       88    88    88 88V8o 88
// 88~~~   88~~~88 88  ooo    88    88 V8o88 88~~~88    88       88    88    88 88 V8o88
// 88      88   88 88. ~8~   .88.   88  V888 88   88    88      .88.   `8b  d8' 88  V888
// 88      YP   YP  Y888P  Y888888P VP   V8P YP   YP    YP    Y888888P  `Y88P'  VP   V8P

export interface PaginationStoreProps {
  numberOfItemsPerPage: number;
  setNumberOfItemsPerPage: (numberOfItemsPerPage: number) => void;
}
const paginationStore = immer<PaginationStoreProps>(set => ({
  numberOfItemsPerPage: 10,
  setNumberOfItemsPerPage: (numberOfItemsPerPage: number) => {
    return set(() => ({
      numberOfItemsPerPage,
    }));
  },
}));
export const usePaginationStore = create(
  persist(paginationStore, {
    name: 'pagination',
    storage: createJSONStorage(() => localStorage),
  })
);
