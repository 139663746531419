import { ReactElement } from "react";

export const IntegrationsCard = ({ title, subHeading, image }: { title: string; subHeading: string | ReactElement, image: string
 }) => {
  return (
    <div className="h-full flex flex-col justify-between">
      <a href="#" className="text-left block max-w-sm p-3 bg-white border border-gray-100 rounded-lg shadow  min-h-52 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <img src={image} className="h-20" />
        <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
        <p className="font-normal text-gray-400 dark:text-gray-400">{subHeading}</p>
      </a>
    </div>
  );
}
