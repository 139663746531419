import { rankItem } from "@tanstack/match-sorter-utils";
import {
  FilterFn,
} from "@tanstack/react-table";

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

// Custom date range filter function
export const dateRangeFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const [start, end] = value;
  if (!start && !end) return true;
  const original = row.original
  const d = new Date(original.created_at)
  // filter if start date is set
  if (start && !end) {
    return d >= start;
  }
  // filter if end date is set
  if (!start && end) {
    return d <= end;
  }
  // filter if both start and end date are set
  return d >= start && d <= end;
}