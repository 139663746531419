import { ReactElement, useEffect, useState } from 'react';
import {
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom';
import OrdersLayout from './modules/orders/OrdersLayout';
import InventoryLayout from './modules/inventory/InventoryLayout';
import CustomersLayout from './modules/customers/CustomersLayout';
import IntegrationsLayout from './modules/integrations/IntegrationsLayout';
import { SignedIn, SignedOut, SignIn, useUser, UserButton } from '@clerk/clerk-react';
// import DashboardLayout from './modules/dashboard/DashboardLayout';

const AuthLayout = ({ children }: { children: ReactElement }) => {
  return (
    <div className="min-h-screen text-xs bg-slate-50 self-center flex items-center justify-center mx-auto">
      {children}
    </div>
  );
};

const Sidebar = ({ isOpen, toggleSidebar }: { isOpen: boolean; toggleSidebar: () => void }) => {
  const { pathname } = useLocation();

  const pages = [
    // {
    //   name: 'Dashboard',
    //   path: '/dashboard',
    // },
    {
      name: 'Orders',
      path: '/orders',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      ),
    },
    {
      name: 'Inventory',
      path: '/inventory',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
          />
        </svg>
      ),
    },
    {
      name: 'Customers',
      path: '/customers',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
          />
        </svg>
      ),
    },
    // {
    //   name: 'Users & Permissions',
    //   path: '',
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       strokeWidth={1.5}
    //       stroke="currentColor"
    //       className="size-5"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'Integrations',
    //   path: '/integrations',
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       strokeWidth={1.5}
    //       stroke="currentColor"
    //       className="size-5"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  const bottomPages = [
    {
      name: 'Settings',
      path: '/settings',
    },
    {
      name: 'Help',
      path: '/help',
    },
  ];

  return (
    <div
      className={`z-50 flex flex-col h-full w-64 bg-[#FFFFFF] fixed md:relative transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
      }`}
    >
      <div className="flex items-center h-16 mt-5 ml-6">
        <Link to="/" onClick={toggleSidebar}>
          <img src="/logo/logo-dark-o.png" alt="logo" className="h-12" />
        </Link>
      </div>
      <div className="flex flex-col flex-1 mt-2 overflow-y-auto">
        <ul className="flex flex-col">
          {pages.map(page => (
            <li key={page.name} className="flex px-1">
              <Link
                to={page.path}
                onClick={toggleSidebar}
                className={`flex flex-1 items-center justify-between hover:font-bold p-4 text-black ${
                  pathname === page.path ? 'font-bold text-[#000000]' : ''
                }`}
              >
                <span className={`text-xs ${pathname === page.path ? 'text-[#29CB97]' : ''}`}>
                  {page.icon}
                </span>
                <span className="ml-4 flex-1">{page.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto mb-4">
        <ul className="flex flex-col">
          {/* {bottomPages.map((page) => (
            <li key={page.name} className="flex">
              <Link
                to={page.path}
                onClick={toggleSidebar}
                className={`flex flex-1 items-center justify-between hover:font-bold p-4 text-white ${pathname === page.path ? 'font-bold text-[#29cb97]' : ''}`}
              >
                {page.name}
              </Link>
            </li>
          ))} */}
          <div className="flex flex-1 items-center justify-between hover:font-bold p-4 text-white ml-1">
            <SignedIn>
              <UserButton
                appearance={{
                  elements: {
                    userButtonAvatarBox: 'w-10 h-10', // Custom width and height
                  },
                }}
              />
            </SignedIn>
          </div>
        </ul>
      </div>
    </div>
  );
};

const MainLayout = ({ children }: { children: ReactElement }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col relative">
      <div className="flex items-center justify-between bg-[#173D60] text-white p-4 md:hidden">
        <button onClick={toggleSidebar}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <Link to="/" onClick={toggleSidebar}>
          <img src="/logo/logo-dark-o.png" alt="logo" className="h-12" />
        </Link>
      </div>
      <div className="flex h-full overflow-y-auto relative">
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
            onClick={toggleSidebar}
          ></div>
        )}
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="flex-1 h-full overflow-y-auto bg-slate-200 p-4 z-30 relative">
          {children}
        </div>
      </div>
    </div>
  );
};

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { isSignedIn, isLoaded } = useUser();

  if (!isLoaded) {
    return null; // or a loading spinner
  }

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }

  return <MainLayout>{children ? children : <Outlet />}</MainLayout>;
};

const UnProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { isSignedIn, isLoaded } = useUser();

  if (!isLoaded) {
    return null; // or a loading spinner
  }

  if (isSignedIn) {
    return <Navigate to="/orders" />;
  }

  return <AuthLayout>{children ? children : <Outlet />}</AuthLayout>;
};

const router = createBrowserRouter([
  {
    path: '*',
    element: <Navigate replace to="/orders" />,
  },
  // {
  //   path: '/dashboard/*',
  //   element: (
  //     <ProtectedRoute>
  //       <DashboardLayout />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: '/orders/*',
    element: (
      <ProtectedRoute>
        <OrdersLayout />
      </ProtectedRoute>
    ),
  },
  {
    path: '/customers/*',
    element: (
      <ProtectedRoute>
        <CustomersLayout />
      </ProtectedRoute>
    ),
  },
  {
    path: '/inventory/*',
    element: (
      <ProtectedRoute>
        <InventoryLayout />
      </ProtectedRoute>
    ),
  },
  {
    path: '/integrations/*',
    element: (
      <ProtectedRoute>
        <IntegrationsLayout />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: (
      <UnProtectedRoute>
        <SignIn />
      </UnProtectedRoute>
    ),
  },
]);

export const App = () => {
  useEffect(() => {
    const setViewHeight = () => {
      document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    };
    setViewHeight();
    window.addEventListener('resize', setViewHeight);
    return () => {
      window.removeEventListener('resize', setViewHeight);
      document.documentElement.style.removeProperty('--vh');
    };
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
