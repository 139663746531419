import { useEffect, useState, useMemo } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTable, Column, HeaderGroup, Row, Cell } from 'react-table';
import Papa from 'papaparse';
import { useAuth } from '@clerk/clerk-react';
import Loading from '@/shared/components/Loading';

type InventoryItem = {
  [key: string]: any; // Use index signature to allow dynamic keys
};

const columnOptions = ['name', 'sku', 'quantity'] as const;
type ColumnOption = (typeof columnOptions)[number];

const schema = z.object({
  // columnMapping: z.record(z.string(), z.string().optional()).refine((val) => {
  //   const assignedValues = Object.values(val).filter(Boolean);
  //   const mappedValues = new Set(assignedValues);

  //   // Check for required columns
  //   const hasAllRequired = columnOptions.every(option => mappedValues.has(option));

  //   // Check for duplicates
  //   const hasNoDuplicates = assignedValues.length === mappedValues.size;

  //   return hasAllRequired && hasNoDuplicates;
  // }, {
  //   message: "All required columns (name, sku, quantity) must be mapped and each can only be assigned once."
  // }),
  overwriteExisting: z.boolean(),
});

type FormValues = z.infer<typeof schema>;

const UploadModal = ({
  onCloseClicked,
  onSaved,
  file,
  setFile,
  apiUrl,
  fetchData,
}: {
  onCloseClicked: () => void;
  onSaved: () => void;
  file: File | null;
  setFile: (file: File | null) => void;
  apiUrl: string;
  fetchData: () => void;
}) => {
  const [parsedData, setParsedData] = useState<InventoryItem[]>([]);
  const [columns, setColumns] = useState<Column<InventoryItem>[]>([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      // columnMapping: {},
      overwriteExisting: true,
    },
  });

  const columnMapping = useWatch({
    control,
    // name: "columnMapping",
  });

  const selectedOptions = new Set(Object.values(columnMapping).filter(Boolean));

  // const getAvailableOptions = (currentValue: string | undefined) => {
  //   return columnOptions.filter(option => option === currentValue || !selectedOptions.has(option));
  // };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // reset form selections
    // setValue("columnMapping", {});

    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      Papa.parse<InventoryItem>(selectedFile, {
        header: true,
        // transformHeader: (header) => header.toLowerCase(),
        complete: results => {
          setParsedData(results.data);
          if (results.meta.fields) {
            const dynamicColumns = results.meta.fields
              .map((field: string) => ({
                Header: field,
                accessor: field,
              }))
              // filter out any headers with no data
              .filter(field => field.accessor !== '');
            setColumns(dynamicColumns);
          }
        },
      });
    }
  };

  const tableInstance = useTable<InventoryItem>({ columns, data: parsedData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const onSubmit = async (data: FormValues) => {
    if (!file) return;
    const token = await getToken();
    setLoading(true);

    // const columnMappingTrimmed = Object.keys(data.columnMapping).reduce((acc, key) => {
    //   const value = data.columnMapping[key];
    //   if (value !== undefined) {
    //     acc[(key).trim()] = value as ColumnOption; // Add type assertion here
    //   }
    //   return acc;
    // }, {} as Record<string, ColumnOption>);

    const formData = new FormData();
    formData.append('file', file);
    // formData.append("columnMapping", JSON.stringify(columnMappingTrimmed));
    formData.append('overwriteExisting', JSON.stringify(data.overwriteExisting));

    fetch(`${apiUrl}/inventory`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        fetchData(); // Refresh data after upload
        onSaved();
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        setLoading(false);
      });
  };

  const columnMappingError =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    errors.columnMapping?.message || errors.columnMapping?.root?.message;

  return (
    <div className="w-full">
      <Loading loading={loading} />
      <div className="py-3 pb-5">Supports CSV files only.</div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {columns.length > 0 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="overflow-x-auto" style={{ height: '500px' }}>
            <table {...getTableProps()} className="min-w-full bg-white">
              <thead className="sticky-header">
                {headerGroups.map((headerGroup: HeaderGroup<InventoryItem>, i) => (
                  <tr className="" {...headerGroup.getHeaderGroupProps()} key={i}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-600 tracking-wider"
                        key={column.id}
                      >
                        <div className="flex flex-col">
                          {column.render('Header')}
                          {/* <Controller
                            control={control}
                            name={`columnMapping.${column.id}`}
                            render={({ field }) => (
                              <select {...field} className="">
                                <option value="">ignore</option>
                                {getAvailableOptions(field.value).map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}
                          /> */}
                        </div>
                        {/* {errors.columnMapping?.[column.id] && (
                          <span className="text-red-500">
                            {errors.columnMapping[column.id]?.message}
                          </span>
                        )} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row: Row<InventoryItem>) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell: Cell<InventoryItem>, i) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-6 py-4 border-b border-gray-300 text-sm leading-5 text-gray-800"
                          key={cell.column.id}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {columnMappingError && (
            <div className="text-red-500 mt-2">{columnMappingError as string}</div>
          )}
          <div className="flex items-center mt-4">
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded disabled:opacity-50"
              disabled={loading}
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCloseClicked}
              className="ml-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
            >
              Close
            </button>
            <label className="ml-4 flex items-center">
              <Controller
                control={control}
                name="overwriteExisting"
                render={({ field }) => (
                  <input
                    type="checkbox"
                    {...field}
                    value={field.value ? 'true' : 'false'}
                    checked={!!field.value}
                    className="form-checkbox h-5 w-5 text-green-600"
                  />
                )}
              />
              <span className="ml-2 text-gray-700">Overwrite existing inventory</span>
            </label>
          </div>
        </form>
      )}
    </div>
  );
};

export default UploadModal;
