import { Customer as CustomerType, Employee as EmployeeType } from '../../types';

export const CustomerInfo = ({
  customer,
  employee,
}: {
  customer?: CustomerType;
  employee?: EmployeeType;
}) => {
  return (
    <div className="flex flex-col bg-white shadow-sm px-6 pt-2 text-left text-sm rounded-lg h-[295px] overflow-y-auto">
      {/* <h1 className="font-bold mb-4">Customer Info:</h1> */}
      <div className="flex flex-col space-y-2">
        <span className="font-semibold">Customer ID:</span> {customer?.customer_id || ''}
        <span className="font-semibold">Name:</span> {customer?.name || ''}
        <span className="font-semibold">City / State:</span> {customer?.city || ''},{' '}
        {customer?.code_state || ''}
        {/* <span className="font-semibold">State/Province:</span> {customer?.code_state || ''} */}
        {/* <span className="font-semibold">Zip/Postal Code:</span> {customer?.code_zip || ''} */}
        <div className="flex flex-col">
          <span className="font-semibold">Address:</span>
          <span>{customer?.street_name_1 || ''}</span>
          <span>{customer?.street_name_2 || ''}</span>
          <span>{customer?.street_name_3 || ''}</span>
          <span>{customer?.street_name_4 || ''}</span>
          <span>{customer?.code_zip || ''}</span>
        </div>
        <span className="font-semibold">Contact Name:</span> {customer?.contact_name || ''}
        <span className="font-semibold">Telephone:</span> {customer?.telephone_1 || ''}
        {/* <span className="font-bold">Sales Rep:</span> {employee?.name || ""} */}
      </div>
    </div>
  );
};

export default CustomerInfo;
