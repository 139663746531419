import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Integrations from './Integrations';


const IntegrationsLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="flex -h-full mx-auto text-center max-w-screen">
      <Routes>
        <Route path="/" element={<Integrations />} />
      </Routes>
    </div>
  );
};

export default IntegrationsLayout;
