import { Parser } from "@json2csv/plainjs";

interface ColumnMapping {
  [key: string]: string;
}

export const exportSelectedRows = (
  selectedRows: any[],
  filename: string = "export.csv",
  columnMapping?: ColumnMapping
) => {
  const mappedRows = selectedRows.map((row) => {
    const original = row.original;
    if (columnMapping) {
      const mappedRow: any = {};
      for (const [key, value] of Object.entries(columnMapping)) {
        mappedRow[value] = original[key];
      }
      return mappedRow;
    }
    return original;
  });

  const parser = new Parser();
  const csv = parser.parse(mappedRows);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  const timestamp = new Date().getTime(); // get current unix timestamp

  link.setAttribute("href", url);
  link.setAttribute("download", `${filename}-${timestamp}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
