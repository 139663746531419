import moment from 'moment';

// format date strings into a readable format
export function formatDate({
  date,
  format,
  utc,
  timeFormat = '12',
  includeTime = true,
}: {
  date: Date | string;
  format?: string;
  utc?: boolean;
  timeFormat?: string;
  includeTime?: boolean;
}) {
  const momentObj = utc ? moment.utc(date) : moment(date);
  const twelveHTime = timeFormat === '12';
  const timeFormatStr = twelveHTime ? 'h:mm A' : 'HH:mm';
  const dateFormat = 'MMMM Do YYYY';
  const dateTimeFormat = includeTime ? `${dateFormat} ${timeFormatStr}` : dateFormat;
  return momentObj.format(format || dateTimeFormat);
}
