import { usePaginationStore } from '@/store';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ReactPaginate from 'react-paginate';
import { shallow } from 'zustand/shallow';
import SelectComponent from './Select';

export type ReactSelectOptionType = {
  label: string;
  value: string;
};

export const Pagination = ({
  totalItems = 0,
  currentPage = 0,
  setCurrentPage,
  onPageChange,
}: {
  totalItems?: number;
  currentPage?: number;
  setCurrentPage: (n: number) => void;
  onPageChange: (page: { selected: number }) => void;
}) => {
  const { numberOfItemsPerPage, setNumberOfItemsPerPage } = usePaginationStore(
    state => ({
      numberOfItemsPerPage: state.numberOfItemsPerPage,
      setNumberOfItemsPerPage: state.setNumberOfItemsPerPage,
    }),
    shallow
  );

  const pageCount = Math.ceil(totalItems / numberOfItemsPerPage);
  const itemsPerPageList = [1, 2, 5, 10, 25, 50, 100];

  const options = itemsPerPageList.map(n => {
    return {
      value: n,
      label: n,
    };
  });

  const handleSelect = (option: ReactSelectOptionType) => {
    // check if value is a number
    const num = Number(option?.value);
    if (isNaN(num)) {
      setNumberOfItemsPerPage(10);
      return;
    }
    setNumberOfItemsPerPage(num);
  };

  const handlePageChange = (pageObj: { selected: number }) => {
    onPageChange({ selected: pageObj.selected });
    setCurrentPage(pageObj.selected);
  };

  return (
    <section className="flex items-center justify-between mt-6 text-sm text-left">
      <div>
        <div className="text-black">
          Total items: <span className="text-[#173D60]">{totalItems || 0}</span>
        </div>
        {totalItems > 0 && (
          <ReactPaginate
            pageCount={pageCount}
            forcePage={currentPage}
            disableInitialCallback={true}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            containerClassName="flex items-center justify-between space-x-2 my-2 font-semibold"
            pageClassName="flex items-center justify-center text-sm rounded-lg hover:bg-slate-200 cursor-pointer"
            pageLinkClassName="w-full h-full flex items-center justify-center py-1 px-3" // Make the entire box clickable
            activeClassName="flex items-center justify-center text-center bg-[#173D60] text-white"
            previousLabel={<ChevronLeftIcon className="w-5 h-5 hover:text-[#173D60]" />}
            nextLabel={<ChevronRightIcon className="w-5 h-5 hover:text-[#173D60]" />}
            onPageChange={pageObj => handlePageChange(pageObj)}
          />
        )}
      </div>

      <div className="flex items-center space-x-3">
        <span className="text-black">Items per page:</span>
        <div>
          <SelectComponent
            value={{
              value: numberOfItemsPerPage || itemsPerPageList[0],
              label: numberOfItemsPerPage || itemsPerPageList[0],
            }}
            onChange={o => {
              handleSelect(o as ReactSelectOptionType);
            }}
            options={options}
          />
        </div>
      </div>
    </section>
  );
};
